import './botaoImagem.css';

export default function botaoImagem(params) {
    return(
        <div 
            className='containerBotaoImg' 
            style={{backgroundColor: params.corBotao}}
            onClick={() => {
                params.onClick();
            }}
        >   
            <img
                className="imagemFundoBotao"
                src={params.imagemFundo} 
                alt="Garrafas de vinho"
            />
            <div className='divPrecoNome'>
                <p><text className='txtPacote'>{params.nomePacote}</text></p>
                <p><text className='txtPacotePreco'>R$ {params.valorPacote}</text></p>
            </div>
        </div>
    )
}