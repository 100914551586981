import styles from './botaoSair.module.css';
import { IoMdLogOut } from "react-icons/io";

export default function botaoSair(params) {
    return(
        <div 
            className={styles.containerBotao}
            onClick={() => {
                params.onClick();
            }}
        >
            <IoMdLogOut 
                color='#FFF'
                size={30}
            />
            <text className={styles.txtBotao}>{params.textoBotao}</text>
        </div>
    )
}