import './botaoLogo.css';
import { FaRegUser } from "react-icons/fa";

export default function botaoLogo(params) {
    return(
        <div 
            className='containerBotaoLogo' 
            onClick={() => {
                params.onClick();
            }}
        >
            <FaRegUser 
                color='#FFF'
                size={20}
            />
        </div>
    )
}