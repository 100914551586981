import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBa5-tLikdmcUuObApH7uXZ6mx3gzwp1f8",
  authDomain: "degusta-facil-74d8d.firebaseapp.com",
  projectId: "degusta-facil-74d8d",
  storageBucket: "degusta-facil-74d8d.appspot.com",
  messagingSenderId: "208330226700",
  appId: "1:208330226700:web:2f80700fdc44ded17fc229",
  measurementId: "G-NPHF9SXE3Z"
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);