import { useState } from 'react';
import './botao.css';

const Botao = (params) => {

    const [loading, setLoading] = useState(false);

    return(
        <div 
            style={{backgroundColor: params.colorButton}}
            className='containerBotao' 
            onClick={() => {
                if(loading){
                    return;
                }

                setLoading(true);
                params.onClick();

                setTimeout(() => {
                    setLoading(false);
                },1000)
            }}
        >
            <text style={{backgroundColor: params.colorButton}} className='txtBotao'>{params.textoBotao}</text>
        </div>
    )
}

export default Botao;