import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './apresentacao.css';

import iconeSemFundo from '../../assets/images/iconeSemFundo.png';
import barrisDeVinho from '../../assets/images/barrisDeVinho.jpg';
import tacaBrindando from '../../assets/images/tacaBrindando.png';

import sommelierIniciante from '../../assets/images/sommelierIniciante.png';
import sommelierAvancado from '../../assets/images/sommelierAvancado.png';
import sommelierMaster from '../../assets/images/sommelierMaster.png';

import Botao from '../../components/botao';
import BotaoLogo from '../../components/botaoLogo';
import BotaoImagem from '../../components/botaoImagem';

function Apresentacao() {
  const navigate = useNavigate();
  const myRef = useRef();


  return (
    <div className="apresentacao">
      <div className="imagemFundo">
        <img 
          className="iconeInicial"
          src={iconeSemFundo} 
          alt="icone"
        />
        <div className="sloganInicial">
          <p className="sloganInicialTxt">A degustação perfeita, sem sair de casa.</p>
        </div>
      </div>
      <img 
        className="imagemFundoBarril"
        src={barrisDeVinho} 
        alt="Barris de vinho"
      />
      <div className="divLinha">
        <div>
          <p className="txtAssinaturaVinho" style={{fontSize: 30}}>ASSINATURA</p>
          <p className="txtAssinaturaVinho">DE VINHOS</p>
        </div>
        <img 
          className="imagemEntreFotosTaca"
          src={tacaBrindando} 
          alt="Taça de vinho"
        />
        <div className="divAssinaturaVinho">
          <p className="txtAssinaturaVinhoDesc"><text>Os vinhos por assinatura, são pensados para garantir que você sempre tenha à disposição vinhos selecionados. </text></p>
          <p className="txtAssinaturaVinhoDesc"><text>A cada mês, você receberá em sua casa uma seleção exclusiva, acompanhada de uma carta elaborada para enriquecer seu conhecimento e tornar sua experiência de degustação ainda mais especial. </text></p>
          <p className="txtAssinaturaVinhoDesc"><text>Nossa missão é facilitar sua jornada pelo mundo dos vinhos, trazendo qualidade e praticidade para o seu dia a dia.</text></p>
        </div>
      </div>
      <div className='divComoFunciona'>
        <div className="divLinhaComoFunciona">
          <div className="divComoFuncionaQuadrado">
            <text className="txtAssinaturaVinhoDesc"  style={{fontSize: 48}}>COMO FUNCIONA ? </text>
          </div>
          <div className="divComoFuncionaQuadrado">
            <p><text className="txtAssinaturaVinhoDesc">{"• Escolha o pacote que mais te agradar! "}</text></p>
            <p><text className="txtAssinaturaVinhoDesc">{"• Faça seu cadastro. "}</text></p>
            <p><text className="txtAssinaturaVinhoDesc">{"• Informe local de entrega. "}</text></p>
            <p><text className="txtAssinaturaVinhoDesc">{"• Defina a forma de pagamento. "}</text></p>
            <p><text className="txtAssinaturaVinhoDesc">{"• Finalize sua assinatura. "}</text></p>
            <p><text className="txtAssinaturaVinhoDesc">{"• Receba no conforto de sua casa os vinhos que selecionamos para vocês. "}</text></p>
            <p><text className="txtAssinaturaVinhoDesc">{"• Aproveite a degustação! "}</text></p>
            <div style={{justifyContent: 'center', display: "flex"}}>
              <Botao
                colorButton={"#690B23"}
                onClick={() => {
                  myRef.current?.scrollIntoView()
                }}
                textoBotao={"Conheça nossos pacotes!"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='divEscolhaPacote' ref={myRef}>
        <text className='txtEscolhaPacote'>ESCOLHA SEU PACOTE</text>
        <div className='divLinhaPacotes'>
          <BotaoImagem
            onClick={() => {
              console.log("Sommelier Iniciante")
            }}
            imagemFundo={sommelierIniciante}
            nomePacote={"Sommelier Iniciante"}
            valorPacote={"100,00"}
            corBotao={"#690B23"}
          />
          <BotaoImagem
            onClick={() => {
              console.log("Sommelier Avançado")
            }}
            imagemFundo={sommelierAvancado}
            nomePacote={"Sommelier Avançado"}
            valorPacote={"200,00"}
            corBotao={"#52091B"}
          />
          <BotaoImagem
            onClick={() => {
              console.log("Sommelier Master")
            }}
            imagemFundo={sommelierMaster}
            nomePacote={"Sommelier Master"}
            valorPacote={"400,00"}
            corBotao={"#690B23"}
          />
        </div>
      </div>
      <div className='divLinhaContatos'>
        <div className='divContatos'>
          <div>
            <p><text style={{fontSize: 55}}>DEGUSTA</text></p>
            <p><text style={{fontSize: 55}}>FÁCIL</text></p>
          </div>
          <div>
            <p><text>sac@degustafacil.com.br</text></p>
            <p><text>{"(11) 98054-5156"}</text></p>
            <p><text>{"(11) 97051-3390"}</text></p>
          </div>
          <div>
            <p><text style={{fontSize: 25}}>FAÇA SUA ASSINATURA</text></p>
            <p><text style={{fontSize: 25}}>AGORA MESMO!</text></p>
          </div>
        </div>
        <div
          className="imagemTacaContatos"
        />
      </div>

      <div className="botaoLogar">
        <BotaoLogo
          onClick={() => {
            navigate("/login");
          }}
        />
      </div>
    </div>
  );
}

export default Apresentacao;
