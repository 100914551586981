export function IsEmail(email){
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export function IsSenhaForte(senha){
    var aoMenosUmDigito = /\d/;             // deve conter ao menos um dígito
    var aoMenosUmaMinuscula = /[a-z]/;           // deve conter ao menos uma letra minúscula
    var aoMenosUmaMaiuscula = /[A-Z]/;          // deve conter ao menos uma letra maiúscula
    var aoMenosOitoCaracteres = /^[0-9a-zA-Z$*&@#]{8,}$/;  // deve conter ao menos 8 dos caracteres mencionados

    let status = true;

    if(aoMenosUmDigito.test(senha)){
        status = false;
    }

    if(aoMenosUmaMinuscula.test(senha)){
        status = false;
    }

    if(aoMenosUmaMaiuscula.test(senha)){
        status = false;
    }

    if(aoMenosOitoCaracteres.test(senha)){
        status = false;
    }

    return {
        sucesso: status,
        digito: aoMenosUmDigito.test(senha),
        minuscula: aoMenosUmaMinuscula.test(senha),
        maiuscula: aoMenosUmaMaiuscula.test(senha),
        oitoCaracteres: aoMenosOitoCaracteres.test(senha),
    }

}