


import styles from './textoMeses.module.css';

export default function textoMeses(params) {
    return(
        <div className={styles.divTxtMeses}>
            <p>Recebidos: <span>{params.recebidos}</span></p>
            <p>Meses conosco: <span>{params.mesesConosco}</span></p>
            <p>Meses consecutivos: <span>{params.mesesConsecutivos}</span></p>
        </div>
    )
}