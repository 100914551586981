import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdEdit } from "react-icons/md";

import iconePreto from '../../assets/images/iconePreto.png';

import BotaoSair from '../../components/botaoSair';
import BotaoDourado from '../../components/botaoDourado';
import BotaoCancelar from '../../components/botaoCancelar';
import TextoMeses from '../../components/textoMeses';

import styles from './menu.module.css'
import AuthContext from '../../context/auth';

function Menu() {

    const { logOut, user } = useContext(AuthContext);

    console.log("user - ", user)

    const [nivelAssinaturaUsuario, setNivelAssinaturaUsuario] = useState("Sommelier Iniciante");

    const navigate = useNavigate();

    return (
        <div className={styles.divPrincipal}>
            <div className={styles.divEsquerda}>
                <div>
                    <h2>Bem-vindo!</h2>
                    <h1>{user?.displayName ? user?.displayName : "-"}</h1>
                    <div style={{marginTop: 30, marginBottom: 30}}>
                        <div className={styles.linha}>
                            <h2>Endereço cadastrado</h2>
                            <span>
                                <MdEdit 
                                    color='white'
                                    size={30}
                                />
                            </span>
                        </div>
                        <h3>Rua Zike tuma, 402, Jardim Ubirajara - São Paulo - SP - 82B - 04458-000</h3>
                    </div>
                    <div>
                        <div className={styles.linha}>
                            <h2>Forma de pagamento</h2>
                            <span>
                                <MdEdit 
                                    color='white'
                                    size={30}
                                />
                            </span>
                        </div>
                        <h3>Cartão de crédito</h3>
                        <h3>**** **** **** 3019</h3>
                    </div>
                </div>
                <BotaoCancelar
                    onClick={() => navigate("../cancelandoAssinatura")}
                    textBotao={"Cancelar assinatura"}
                />
            </div>
            <div className={styles.divDireita}>
                <div className={styles.divAlinhamentos}>
                    <BotaoSair
                        onClick={logOut}
                        textoBotao={"Sair"}
                    />
                    <img 
                        className={styles.imagemIcone}
                        src={iconePreto} 
                        alt="Icone"
                    />
                </div>
                <div className={styles.divAlinhamentos}>
                    <h2 className={styles.txtNivel}>{nivelAssinaturaUsuario}</h2>
                    <BotaoDourado
                        onClick={() => { console.log("Upgrade")}}
                        textoBotao={"Upgrade"}
                    />
                </div>
                <TextoMeses
                    recebidos={"10"}
                    mesesConosco={"10"}
                    mesesConsecutivos={"10"}
                />
            </div>
        </div>
    );
}

export default Menu;
