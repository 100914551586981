import styles from './botaoDourado.module.css';

export default function botaoDourado(params) {
    return(
        <div 
            className={styles.containerBotao}
            onClick={() => {
                params.onClick();
            }}
        >
            <text className={styles.txtBotao}>{params.textoBotao}</text>
        </div>
    )
}