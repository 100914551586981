import React, { useState } from 'react';
import './inputSenha.css';
import { FiEye, FiEyeOff } from "react-icons/fi";

export default function InputSenha(params) {

    const [verSenha, setVerSenha] = useState(false);

    return(
        <div className='containerInput'>
            <input {...params} onChange={params.onChange} type={!verSenha ? 'password' : 'text'} required/>
            <div className='containerInputEyeIcon'>
                {verSenha ?
                    <FiEye 
                        color='#FFF'
                        size={26}
                        onClick={() => {
                            setVerSenha(!verSenha)
                        }}
                    />
                :
                    <FiEyeOff 
                        color='#FFF'
                        size={26}
                        onClick={() => {
                            setVerSenha(!verSenha)
                        }}
                    />
                }
            </div>
        </div>
    )
}