import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './assinaturaCadastrada.module.css';

import iconePreto from '../../assets/images/iconePreto.png';

import BotaoCancelar from '../../components/botaoCancelar';

function AssinaturaCadastrada() {

    const navigate = useNavigate();

    return (
        <body className={styles.bodyCancelando}>
            <img 
                className={styles.imagemIcone}
                src={iconePreto} 
                alt="Icone"
            />
            <div className={styles.texoMeio}>
                <h3>Obrigado por assinar!</h3>
                <h1>Desfrute dos melhores vinhos conosco.</h1>
            </div>
            {/* <div className={styles.linha}> */}
                <BotaoCancelar
                    onClick={() => {navigate("/")}}
                    botaoVerde={true}
                    textBotao={"Menu"}
                />
            {/* </div> */}
        </body>
    );
}

export default AssinaturaCadastrada;
