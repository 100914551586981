import './inputText.css';

export default function inputText(params) {
    return(
        <div 
            className='containerInput'
        >
            <input onChange={params.onChange} {...params} type='text' required/>
        </div>
    )
}